import * as React from 'react';
import { Typography } from '@mui/material';
import KofiImage from "../../assets/encicloffee.png"
import KofiLogo from "../../assets/kofi_logo.png"
import ButtonComponent from './ButtonComponent';

const KofiBanner = () => {
    return (
        <div style={{ cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", background: "linear-gradient(270deg, #E54C4C 0%, rgba(0,0,0,0) 50%)" }}
            onClick={() => {
                window.open("https://ko-fi.com/duhgetsu", '_blank');
            }}
        >
            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", maxWidth: 600 }}>
                <img style={{ maxHeight: 150 }} src={KofiImage} alt={"LQN Enciclopédia"} />
                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <Typography color={"#E54C4C"} fontSize={30} fontWeight={"bold"}>
                        Ajude a Enciclo-chan!
                    </Typography>
                    <Typography>
                        Apoie com qualquer quantia para que a Enciclo-chan continue catalogando quadrinhos nacionais!
                    </Typography>
                    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                        <ButtonComponent image={KofiLogo} imageText={"Logo Ko-fi"} buttonColor={"#E54C4C"} buttonText={"Apoie no Ko-fi"} textColor={"white"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KofiBanner;