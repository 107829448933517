import * as React from 'react';
import KofiBanner from './KofiBanner';
import TwitchBanner from './TwitchBanner';
import DiscordBanner from './DiscordBanner';
import LinksBanner from './LinksBanner';
import CarouselPage from './CarouselPage';
import useWindowDimensions from '../../utils/windowDimension';

const AboutPage = () => {
    const { height } = useWindowDimensions()

    return (
        <div style={{ display: "flex", flex: 1, flexDirection: "column", minHeight: height - 298 }}>
            <div style={{ flex: 1, display: "flex" }}>
                <div style={{ flex: 2, display: "flex", justifyContent: "center" }}>
                    <CarouselPage />
                </div>
                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <LinksBanner />
                    <TwitchBanner />
                    <DiscordBanner/>
                    <KofiBanner />
                </div>
            </div>
        </div>
    );
}

export default AboutPage;