import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';
import StaffImage from "../../assets/staff.png"
import Desafio4 from "../../assets/DESAFIO 4.png"
import Desafio5 from "../../assets/DESAFIO 5.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTheme } from '@mui/material/styles';

const CarouselPage = () => {
    const [index, setIndex] = useState(0);
    const theme = useTheme();

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const slides = [
        {
            image: StaffImage,
            title: "A Nova Enciclopédia",
            description: "A nova enciclo nasceu, nos ajude reportando qualquer problema e nos dê suas sugestões no discord!",
            link: "https://discord.com/channels/1047301441062178836/1047301441502576705"
        },
        {
            image: Desafio4,
            title: "DESAFIO - Seu personagem foi preso!?",
            description: "Participe no Discord monstrando a altura do seu personagem e o motivo pelo qual ele foi em cana.",
            link: "https://discord.com/channels/1047301441062178836/1282442590729539594"
        },
        {
            image: Desafio5,
            title: "DESAFIO - INKTOBER DA LQN",
            description: "Uma lista com temas variados para serem desenhados diariamente!",
            link: "https://discord.com/channels/1047301441062178836/1290520889812713554"
        }


    ]

    return (
        <Carousel variant={theme.palette.mode === "dark" ? "light" : "dark"} interval={null} slide={true} activeIndex={index} onSelect={handleSelect} style={{ flex: 1 }}>
            {slides.map((slide) => {
                return (
                    <Carousel.Item key={slide.title} style={{ flex: 1, border: "0px solid", cursor: "pointer" }} onClick={() => window.open(slide.link, '_blank')}>
                        <div style={{ display: "flex", justifyContent: "center", flex: 1, height: 621 }}>
                            <div style={{ position: "absolute", bottom: 0, height: 621, width: "100%", flex: 1, background: "linear-gradient(0deg, rgba(0,0,0,100) 0%, rgba(116,18,18,0) 50%)" }} />
                            <img src={slide.image} height={621} style={{ objectFit: "contain" }} alt={slide.title}/>
                        </div>
                        <Carousel.Caption style={{ color: "white" }}>
                            <h3>{slide.title}</h3>
                            <p>{slide.description}</p>
                        </Carousel.Caption>
                    </Carousel.Item>

                )
            })}
        </Carousel>
    );
}

export default CarouselPage