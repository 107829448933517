import * as React from 'react';
import ComicsPage from "./ComicsPage/ComicsPage.js";
import CountersPage from "./CountersPage/CountersPage.js";
import GalleriesPage from "./GalleriesPage/GalleriesPage.js";
import AboutPage from "./AboutPage/AboutPage.js";
import LoadingPage from "./LoadingPage.js";
// import LoadingImage from "../assets/LOADCICLO.gif"

//Redux
import { getIsFetching } from "../_reducers/comics/isFetching.js";
import { getComics } from '../_reducers/comics/index.js';
import { connect } from "react-redux"


const ContentPage = ({ isFetching, activePage, changeOpenModal, comics }) => {

  return (<div style={{ display: "flex" }}>
    {activePage === 3 ? <AboutPage /> : isFetching && comics.length === 0 ? <LoadingPage /> :
      activePage === 0 ? <ComicsPage openModal={changeOpenModal} /> :
        activePage === 1 ? <CountersPage openModal={changeOpenModal} /> :
          activePage === 2 ? <GalleriesPage openModal={changeOpenModal} /> : "404"}
  </div>);
}

export default connect(
  (state) => {
    return {
      isFetching: getIsFetching(state.comics) || false,
      comics: getComics(state)
    };
  },
  {}
)(ContentPage);

