import * as React from 'react';
import { Typography } from '@mui/material';
import StaffBImage from "../../assets/STAFFB.png"

const TwitchBanner = () => {
    return (
        <div style={{ cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", background: "linear-gradient(270deg, #008055 0%, rgba(0,0,0,0) 50%)" }}
            onClick={() => {
                window.open("https://linktr.ee/bsougo", '_blank');
            }}
        >
            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", maxWidth: 600 }}>
                <img style={{ maxHeight: 150 }} src={StaffBImage} alt={"LQN Bzinho"} />
                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <Typography color={"#008055"} fontSize={30} fontWeight={"bold"}>
                        Fique por dentro dos links!
                    </Typography>
                    <Typography>
                        Youtube, instagram, tiktok... A LQN traz uma enchurrada de conteúdinho, <b>não perca nada!</b>
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default TwitchBanner;