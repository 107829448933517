import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import Link from '@mui/material/Link';

import EncicloLoading from "../../assets/LOADING.png"

//Redux
import { fetchComics } from "../../_actions/comics";
import { getComic } from "../../_reducers/comics/byId";
import { getIsFetching } from "../../_reducers/comics";
import { connect } from "react-redux"


const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const GalleryItem = ({ item, comic, openModal, isFetching, fetchComics }) => {
    const [errorImage, setErrorImage] = useState(false)
    const prevAmount = usePrevious({ isFetching });
    const img = new Image();
    // img.src = expiredImage;
    img.src = item.image;

    useEffect(() => {
        if (prevAmount?.isFetching === true && isFetching === false) {
            setErrorImage(false)
        }
    }, [prevAmount?.isFetching, isFetching])

    const RenderTitle = () => {
        return <Link color={"white"} onClick={() => openModal(comic.id)} style={{ cursor: "pointer" }} underline="hover">
            {comic?.name}
        </Link>
    }

    return (
        <ImageListItem key={item.id}>
            <img
                style={{ minHeight: 200 }}
                srcSet={errorImage ? EncicloLoading : img.src}
                src={errorImage ? EncicloLoading : img.src}
                alt={item.name}
                loading="lazy"
                onError={e => {
                    setErrorImage(true)
                    if (!isFetching) {
                        fetchComics()
                    }
                }}
            />
            <ImageListItemBar
                sx={{
                    background:
                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 30%, ' +
                        'rgba(0,0,0,0.3) 90%, rgba(0,0,0,0) 100%)',
                }}
                title={item.name}
                position="top"
            />
            <ImageListItemBar
                title={RenderTitle()}
                subtitle={comic.autor[0]}
                actionIcon={
                    <div>
                        {comic.insta &&
                            <IconButton
                                href={comic.insta}
                                target="_blank"
                                sx={{ color: 'rgba(255, 255, 255)' }}
                                aria-label={`info about ${item.name}`}
                            >
                                <InstagramIcon />
                            </IconButton>}
                        {comic.twitter &&
                            <IconButton
                                href={comic.twitter}
                                target="_blank"
                                sx={{ color: 'rgba(255, 255, 255)' }}
                                aria-label={`info about ${item.name}`}
                            >
                                <XIcon />
                            </IconButton>}
                    </div>
                }
            />
        </ImageListItem>
    );
}

export default connect(
    (state, prop) => {
        return {
            comic: getComic(state.comics, prop.comic_id),
            isFetching: getIsFetching(state)
        };
    },
    {
        fetchComics,
    }
)(GalleryItem);
