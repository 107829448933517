export const loadingMessages = [
    "Carregando as mil páginas de Arthur e o Sol",
    "Carregando todas as Desculpas, eu gosto de você",
    "Carregando picolés leitosos",
    "Carregando doguinhos em busca de vingança",
    "Carregando ferramentas douradas",
    "Carregando a Excalibur",
    "Carregando todos os planos maléficos do frango",
    "Carregando as lendas de vera cruz",
    "Carregando todos os contadores inúteis",
    "Carregando o Arcano das pérolas",
    "Carregando pacotes do Kaleb",
    "Carregando monstros do cangaço",
    "Carregando todas as Escolas que ninguém tem aula de verdade",
    "Carregando todos os Tchecos",
    "Carregando o fundo do B",
    "Carregando as skins do B",
    "Carregando os pings, os pongs e os dramas",
    "Carregando os colares da Colares",
    "Carregando os aúdios da Pri",
    "Carregando furries",
    "Carregando a pirocâmera",
    "Carregando todas camisetas otaku",
    "Carregando as calcinhas do lobisomen",
    "Carregando pacotes de miojo vencido",
    "Carregando o Oda pra longe daqui",
    "Carregando o ego dos ruivinhos",
    "Carregando a liberdade do Hashimura",
    "Carregando a letra da abertura pra Hanna",
    "Carregando as quotes da live",
    "Carregando o OBS do B",
    "Carregando os jogos suspeitos da Nidoriko",
    "Carregando os Z's da Clau",
    "Carregando os projetos secretos",
    "Carregando a chama do quadrinista",
    "Carregando quadrinhos de qualidade duvidosa",
    "Carregando o espadão de Alice",
    "Carregando a fofura da Nori",
    "Carregando um gancho de direita",
    "Carregando algum namorado para o Drácula"
]