import * as React from 'react';
import { useState, useEffect } from 'react';
import LoadingImage from "../assets/ENCICLOCARREGANDO.png"
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { loadingMessages } from "../utils/loadingMessages.js"
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

//Redux
import useWindowDimensions from '../utils/windowDimension.js';

function shuffle(array) {
    let currentIndex = array.length;
  
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
  
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

const shuffledLoadingMessages = shuffle(loadingMessages)

const LoadingPage = () => {
    const { height } = useWindowDimensions()
    const [textIdx, setTextIdx] = useState(Math.floor(Math.random() * loadingMessages.length))
    const [textDots, setTextDots] = useState("")
    const theme = useTheme();

    useEffect(() => {
        const startLoadingMessages = setInterval(() => {
            if (textDots === "...") {
                let currentIdx = textIdx;
                setTextIdx(currentIdx + 1);
                setTextDots("")
            } else {
                const newTextDots = textDots.concat(".")
                setTextDots(newTextDots)
            }
        }, 1000);
        return () => {
            clearInterval(startLoadingMessages)
        }
    }, [textIdx, textDots])


    return (<div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
        <div style={{ position: "absolute", bottom: 40, color: theme.palette.primary.main }}>
            <Typography style={{
                fontSize: 40,
                fontWeight: "bold",
                textShadow: "-3px -3px 0 white, 0px -3px 0 white, 3px -3px 0 white, 3px 0px 0 white, 3px 3px 0 white, 0px 3px 0 white, -3px 3px 0 white, -3px 0px 0 white"
            }}>
                {shuffledLoadingMessages[textIdx % shuffledLoadingMessages.length] + textDots}
            </Typography>
        </div>
        <img style={{ maxHeight: height - 298, objectFit: "contain" }} src={LoadingImage} alt={"LQN Enciclopédia"} />
    </div>
    )
}

export default LoadingPage;