import * as React from 'react';
import ComicsListPages from "./ComicsListPages"
import EncicloImage from "../../assets/enciclo loading.png"

const ComicsPage = ({ openModal }) => {

    return (
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
            <div style={{ flex: 3 }}>
                <ComicsListPages openModal={openModal} />
            </div>
            <div style={{ display: "flex", flex: 1, marginRight: 20, alignItems: "center", flexDirection: "column" }}>
                <img style={{maxWidth: 350}} src={EncicloImage} alt={"LQN Enciclopédia"} />
                <iframe
                    style={{ border: 0 }}
                    title="Inline Frame Example"
                    width="100%"
                    height="700"
                    src="https://notionforms.io/forms/indique-um-quadrinho-para-lermos-juntos-2"
                    loading='eager'
                />
            </div>
        </div>
    );
}

export default ComicsPage;