import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      backgroundColor: 'white',
    },
  });
  
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(2),
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#fff',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }),
  );

export const MenuTabs = ({ menuButtons, changeActiveMenu }) => {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event, newValue, test) => {
        setValue(newValue);
        changeActiveMenu(newValue)
      };
      
    return (
        <Box sx={{ bgcolor: theme.palette.primary.main }}>
        <StyledTabs centered value={value} onChange={handleChange}>
             {menuButtons.map((button) => <StyledTab key={button.title} label={button.title} />)}
        </StyledTabs>
        </Box>
    )
}
