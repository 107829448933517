import * as React from 'react';
import { Typography } from '@mui/material';
import StaffRaphaImage from "../../assets/STAFFRAPHA.png"

const DiscordBanner = () => {
    return (
        <div style={{ cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", background: "linear-gradient(270deg, #5562EA 0%, rgba(0,0,0,0) 50%)"}}
            onClick={() => {
                window.open("https://discord.gg/hSY85KWkQN", '_blank');
            }}
        >
            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", maxWidth: 600 }}>
                <img style={{ maxHeight: 150 }} src={StaffRaphaImage} alt={"LQN Enciclopédia"} />
                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <Typography color={"#5562EA"} fontSize={30} fontWeight={"bold"}>
                        Entre na comunidade!
                    </Typography>
                    <Typography>
                        O lugar onde o fandom do quadrinho nacional se encontra! Participe dos nossos desafios, collabs, chats e muito mais.
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default DiscordBanner;